/* eslint-disable sort-imports */
import './App.scss';
import { Article } from './Components';
import rb from './images/richard-bagnall.jpg';
import React from 'react';
import preval from 'preval.macro';

import fetchLogo from './images/fetchai_logo.png';

import optibriumLogo from './images/optibrium_logo.png';
import optibrium from './images/optibrium.jpg';

import congenicaLogo from './images/congenica_logo.png';
import congenica from './images/congenica.jpg';

import metailMobileLogo from './images/metail-logo-mobile.png';
import metailLogo from './images/metail-logo.png';
import metail from './images/metail.jpg';

import prismastarLogo from './images/prismastar-logo.png';
import prismastarMobileLogo from './images/prismastar-logo-mobile.png';
import prismastar from './images/prismastar3.jpg';

import jagexLogo from './images/jagex.gif';
import jagex from './images/jagex.jpg';
import eightRealms from './images/8realms.jpg';

import brightLogo from './images/brightlogo.jpg';
import bright from './images/bright.jpg';

import harmonyLogo from './images/harmony-internet.png';
import harmonyMobileLogo from './images/harmony-internet-mobile.png';
import harmony from './images/millow_farm.jpg';

import proquestLogo from './images/proquest.jpg';
import proquest from './images/proquest_office.jpg';

import aspentechLogo from './images/aspentech.gif';
import aspentechOffice1 from './images/sheraton_house_lr.gif';
import aspentechOffice2 from './images/aspentechoffice.gif';

import fugroLogo from './images/fugro.gif';
import rietbaan from './images/rietbaan.gif';

import smallworldLogo from './images/smallworld.png';
import smallworldMobileLogo from './images/smallworld-mobile.png';
import brunswickHouse1 from './images/brunswick_house_side.gif';
import europaring from './images/60_Europaring.gif';
import brunswickHouse2 from './images/brunswick_house.gif';
import smallworldWCP from './images/sw_wcp.png';

import gardlineLogo from './images/gardline.png';
import gardlineMobileLogo from './images/gardline-mobile.png';
import shLogo from './images/scottish_hydro.gif';
import shOffice from './images/hydro_office.gif';

import semaLogo from './images/sema-logo.png';
import semaMobileLogo from './images/sema-logo-mobile.png';
import semaOffice from './images/discovery_house.gif';

import cimageLogo from './images/cimage.gif';
import cimageOffice from './images/cimage_office.gif';

import robaryLogo from './images/robary.gif';

import cisLogo from './images/cis.gif';
import harstonMill from './images/cis_office.gif';

import metierLogo from './images/metier.gif';
import metierOffice from './images/metier_office.gif';
import blackbird from './images/blackbird.jpg';

import ueaLogo from './images/uea_logo.gif';
import joroLogo from './images/jorologo.gif';
import uea from './images/uea.gif';

import vrme from './images/vrme_lr.gif';
import bscSvg from './images/bsc_logo_flat.svg';
/* eslint-enable sort-imports */

class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = ({
			expandAll: false,
		});

		this.child = React.createRef();
		this.expandAll = this.expandAll.bind(this);
	}

	componentDidMount = () => {
		const commonStyles = 'padding: 8px;font-size: 36px;font-weight: bold;font-family: "Arial Black";';

		/* eslint-disable */

		setTimeout(console.log.bind(console, '%cBagnall%cSoftware%cConsultants%cLtd',
			`${commonStyles}color: blue;`,
			`${commonStyles}color: red;`,
			`${commonStyles}color: green;`,
			`${commonStyles}color: black;`));

		setTimeout(console.log.bind(console, '%cHello fellow web dev, welcome!', 'font-size: 24px;font-family:arial;font-weight: bold;'));

		setTimeout(console.log.bind(console, '%cHave you tried this site on a mobile device? Did you notice the haptic feedback? I hope you invite me to interview so that I can show you some of my sample sites.',
			'font-size: 18px;font-family:arial;'));

		/* eslint-enable */

	};

	expandAll = () => {
		const { expandAll } = this.state;
		this.setState({
			expandAll: !expandAll, // Just a flip will work, doesn't have to be true.
		});
	};

	render = () => {
		const strOptibrium = 'Optibrium';
		const strFetch = 'Fetch.ai';
		const strCongenica = 'Congenica';
		const strMetail = 'Metail';
		const strPS = 'PrismaStar';
		const { expandAll } = this.state;
		const today = new Date();
		const yearNow = today.getFullYear();

		return (
			<div className='App'>
				<React.StrictMode>
					<div className='bsc-gradient'>
						<div className='bsc-texture'>
							<header>
								<img src={rb} alt='Richard Bagnall' className='portrait noprint' />
								<h1>Richard&nbsp;Bagnall's <br className='noprint' />Curriculum&nbsp;Vitae</h1>
							</header>
							<div id='content'>
								<p className='right updated'>Last updated <span className='calendarContainer'><span className='calendar'>
									<span className='date'>{preval`module.exports = new Date().getDate();`}</span>
									<span className='month'>{preval`module.exports = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][(new Date().getMonth())].slice(0,3);`}</span>
									<span className='year'>{preval`module.exports = new Date().getFullYear();`}</span></span></span></p>

								<p className='printonly small'>This printed copy has some sections hidden
									for brevity. To view the full and most up to date version, please
									visit <a href='https://cv.bagnall.co.uk' title='https://cv.bagnall.co.uk'>https://cv.bagnall.co.uk</a>.</p>

								{/* <p style='font-weight: bold; color: #990000; display: none;'>Currently seeking permanent or
								contract employment.</p>*/}

								<p>I have over {`${yearNow - 1983} `} years of experience in the computing industry in software and web
									development. I worked in cutting edge industries
									at the time they were developing and thriving, for example CAD/CAM, GIS and life sciences.</p>

								<p>I'm also interested in UX design and feel that my web developer experience is of benefit to this as I
									can rapidly prototype examples to support
									wireframes where necessary.</p>

								<p>I am looking for a worthwhile job where my day to day work can make a difference, however small, to
									improve life and quality of life for others.</p>

								<p>I have good communication and interpersonal skills, getting on well with my colleagues, communicating
									across teams to get things done as
									demonstrated by having supervised a software team remotely in Ukraine.
									Also working with colleagues in Belarus and Serbia recently.</p>

								<p className='noprint'>Don't take my word for it though, click some of the reference links in
									this CV to see
									what others have
									said <span className='tick' title='Click on these links to read references'></span>. Or view more recent <a href="https://www.linkedin.com/in/bagnall/#recommendations" target="_blank" rel="noreferrer">recommendations on LinkedIn</a>.</p>

								<p className='noprint'>Click on the arrow symbols <span className='arrow' title='Click on the arrows to open/close sections'></span> to open and close sections or <span id='expandAll' onClick={() => {
									this.expandAll();
								}}>click here to expand all</span>.</p>

								<p style={{ fontSize: '0.8em' }} className='noprint'>This CV is print compatible. <u>Only expanded sections print</u>.</p>

								{/* P E R S O N A L   D E T A I L S */}
								<Article
									id='PersonalDetails'
									title={`Personal Details`}
									expandedByDefault={true}
									expandNow={expandAll}
								>
									<table id='personal'>
										<tbody>
											<tr>
												<th>Name:</th>
												<td>RICHARD BAGNALL</td>
											</tr>
											<tr>
												<th>Email:</th>
												<td className='emailAddress'>
													cv_{`${yearNow - 2010}${today.getMonth() + 1}`}<span>@</span>bagnall.co.uk</td>
											</tr>
										</tbody>
									</table>
								</Article>

								{/* H O B B I E S   A N D   I N T E R E S T S */}
								<Article
									id='HobbiesAndInterests'
									title={`Hobbies & Interests`}
									expandNow={expandAll}
								>
									<p>I enjoy flying and hope to get back to it someday. I enjoy
										cycling, walking, travel and photography when the
										opportunities arise. I have a private pilot’s license and gained my EPC in paragliding. I
										play badminton and run a small badminton club.</p>
								</Article>

								{/* O P T I B R I U M */}
								<Article
									expandedByDefault={true}
									id='Optibrium'
									href='https://optibrium.com'
									logo={optibriumLogo}
									alt={`${strOptibrium} Logo`}
									dates='Feb 2020 - Jul 2024'
									title={`${strOptibrium}`}
									expandNow={expandAll}
								>
									<div className='content'>
										<img className='site right' src={optibrium} alt={`${strOptibrium}`} />
										<p><b>Principle Web Developer, Product Lead & UX Designer</b>. I can't discus anything about my main project at
											Optibrium,
											but it was possibly the project of my career of which I'm most proud. It pushed the capabilities of
											web browsers by finding innovative solutions to challenges.</p>

										<p>As an example, I used canvas for dynamic rubber band line drawing, snapping to targets, drag and drop and editing an SVG dynamically using D3.js.</p>

										<p>I'm both UX designer and developer
											so, I need to remind myself of my golden rule: "Let the designer dream". It's hard to do when you're
											the developer too. </p><p>As a developer, you should never say "no" to an innovative and creative design until
												you've exhausted all possibilities (within the timescale and budget) and come up blank.
												You'll often find a way.</p>

										<p>I designed and developed the&nbsp;
											<a href='https://optibrium.com/products/cerella/' target='_blank' rel="noreferrer">Cerella&trade;</a>
											&nbsp;Admin GUI too and worked on&nbsp;
											<a href='https://optibrium.com/products/stardrop/' target='_blank' rel="noreferrer">StarDrop&trade;</a>
											&nbsp;and&nbsp;
											<a href='https://optibrium.com/products/semeta/' target='_blank' rel="noreferrer">Semeta&trade;</a>
											&nbsp;projects amongst others.</p>

										<p>Front end development using JavaScript, ES6, SASS, React, D3.js, Plotly.js,
										Adobe XD and Adobe Photoshop on Windows.</p>

									</div>{/* .content */}
								</Article>

								{/* F E T C H   A I */}
								<Article
									expandedByDefault={true}
									dates='Oct 2019 - Feb 2020'
									id='Fetch'
									href='https://fetch.ai'
									logo={fetchLogo}
									alt={`${strFetch} Logo`}
									title={`${strFetch} Ltd`}
									expandNow={expandAll}
								>
									<p><b>Web Developer</b>. As a frontend web developer, I was responsible for multiple website
										development using a variety of infrastructue
										including React, WordPress and mkDocs.</p>
									<p>I changed 7 websites to be fully responsive and mobile first.</p>
									<p>I produced a wireframe for user flow for an additional site and helped implement, mentor and
										begin making it responsive.</p>
									<p>I designed the 'hero' section for the community website,
										animating a part of a small town using CSS3 animation. This was a proof of concept but was
										well liked and launched as a live site.</p>
									<p>Front end development using JavaScript, ES6, SASS, React, Adobe XD and Adobe Photoshop on Windows and Mac.</p>
								</Article>

								{/* C O N G E N I C A */}
								<Article id='Congenica'
									expandedByDefault={true}

									logo={congenicaLogo}
									href='https://www.congenica.com'
									alt={`${strCongenica}`}
									title={`${strCongenica} Ltd`}
									dates='Nov 2017 - Sep 2019'
									expandNow={expandAll}
								>
									<img className='site right' src={congenica} alt={`${strCongenica}`} />
									<p><b>UX Designer/Frontend Developer</b>. I was responsible for UX Design, including activities
										such as wireframing and user testing using Balsamiq and
										Adobe XD. I visited the laboratories of Congenica and of our clients to perform user testing
										and analyse and document workflow.</p>
									<p>Front end development using JavaScript, ES6, SASS, React, Redux, Formik, jQuery, CircleCI,
										HTML based report generation using Jinja Python Templating, Jest, Enzyme, Virtualbox Linux
										VM, Adobe XD and Adobe Photoshop.</p>
								</Article>

								{/* M E T A I L */}
								<Article
									expandedByDefault={true}
									id='Metail'
									href='https://metail.com/'
									alt={`${strMetail} Logo`}
									mobileLogo={metailMobileLogo}
									logo={metailLogo}
									dates='Sep 2014 - Aug 2017'
									title={`${strMetail} Ltd`}
									expandNow={expandAll}
								>
									<img className='site right' src={metail} alt={`${strMetail}`} />
									<p><b>Senior Web Engineer</b> and <b>External Engineering Manager</b>.</p>

									<p>Founded in 2008, {`${strMetail} `} is a British company developing online fitting room
										technology, enabling consumers to create a bespoke 3D model of themselves. The
										technology aims to increase consumer confidence in buying clothing online, whilst also
										reducing return rates for the retailer. {`${strMetail} `}'s technology can be incorporated
										into any e-commerce platform and consumers, after entering a few simple measurements,
										can then generate a 'MeModel', which is 94-96% accurate to their specific size.</p>

									<p>In the first couple of years my work involved programming in CoffeeScript on the existing
										MeModel widget and on retailer integrations (analysing the retailer website and writing
										code to place CTAs and the widget into the retailer page).</p>

									<p>I also worked on some experimental technology and was pleased to be able to offer canvas
										solutions for atmospheric perspective and click through for transparent canvas areas.</p>

									<p>It was a personal interest of mine to get involved at the design stage to discuss UX,
										joining in usability testing and prototyping to support design.</p>

									<p>During a period when we had many integrations to implement we utilised the skills of a
										contract agency and my role switched to one of supervising and liaising with that team
										in the Ukraine. When we stopped using outside resources, my role reverted to Web
										Engineer.</p>

									<p>Latterly we used ClojureScript, React, Reagent and Reframe to build a new version of the
										MeModel widget.</p>

									<p>JavaScript, CoffeeScript, LESS, jQuery, Photoshop, Node.js<sup>&reg;</sup>, Grunt, Jenkins,
										git, Gitkraken, Backbone.js, Underscore.js, Marionette.js, HTML5, CSS3, ClojureScript
										(cljs), Material UI</p>
								</Article>

								{/* P R I S M A S T A R */}
								<Article
									expandedByDefault={true}
									id='PrismaStar'
									logo={prismastarLogo}
									mobileLogo={prismastarMobileLogo}
									title={`${strPS}`}
									dates='Ltd Jun 2013 - Sep 2014'
									alt={`${strPS}`}
									expandNow={expandAll}
								>
									<img className='site right' src={prismastar} alt={`${strPS}`} />
									<p><b>Web Developer</b>.</p>

									<p>As a Web Developer at {`${strPS} `}, there was a greater emphasis on JavaScript than I had in
										previous roles. In the Professional Services Organisation team, I worked with {`${strPS} `}'s
										award winning product advisor, tailoring it to the individual needs of each retailer and
										working to develop and enhance new and existing products in the {`${strPS} `} portfolio. In the
										course of this work, I was fortunate to train up on new technologies including Bootstrap and
										Google's AngularJS.</p>

									<p>With big name clients such as Tesco, quality is essential, robustness and reliability are
										vital and an eye for detail to spot where we could enhance our clients' customers' retail
										experience was an additional benefit.</p>

									<p>One of {`${strPS} `}'s products is the product advisor. I was one of the team developing the
										responsive, version 3 product advisor for the company. This used AngularJS and Isotope
										libraries amongst other standards such as Modernizr and jQuery</p>

									<p>I was instrumental in the production of this web based application whilst also mentoring
										other members of the team.</p>

									<p>I also developed a <a href='http://www.bagnall.co.uk/free_stuff/console/index.asp'
										target='_blank' rel='noopener noreferrer'>tablet/smartphone console</a> to assist in debugging. This provides a
										console similar to that provided by Firebug, though this is in page, draggable and
										resizable.</p>

									<p>JavaScript, jQuery, Photoshop, Node.js<sup>&reg;</sup>, Bower, Jenkins, Karma, Subversion,
										Isotope, AngularJS (1), HTML5, CSS3</p>
								</Article>

								{/* J A G E X */}
								<Article id='Jagex'
									expandedByDefault={true}
									href='https://www.jagex.com'
									logo={jagexLogo}
									alt='JagexLogo'
									title='Jagex Ltd'
									dates='Dec 2008 - Jun 2013'
									expandNow={expandAll}
								>
									<img className='site right' src={jagex} alt='Jagex' />

									<p><b>Website Developer</b>. Working on websites which must be top quality, with complete cross
										browser compatibility, accessible and able to work on low end systems and browsers, even
										when
										JavaScript is disabled. Also with an eye to bandwidth not just because of speed of download
										to
										the user, but also because of load on the servers.</p>

									<p>I was instrumental in promoting reusability of page components and scripts, bringing time
										efficiency and consistent look and feel to Jagex projects, for example the rapid development
										of
										the War of Legends website.</p>

									<p>I also constructed HTML newsletters for the company from receipt of photoshop design
										through to a page which can be submitted to Lyris and will be compatible with the top email
										clients such as Outlook, Hotmail, gMail and Yahoo, across browsers.</p>

									<p>From time to time the sites were themed, once a lengthy process, but I organised the
										scripts, procedures and filesystem to provide efficiency there too.</p>
									<Article
										id='RuneScape'
										title='RuneScape'
										expandNow={expandAll}
									>
										<p>We had a cycle of development requiring gradient shaded text to be localised into 3 additional languages.
											That localised text then had to be recreated in PhotoShop, cropped and exported as additional jpegs.
											I developed a technique to enable regular text
											to have a <a href='https://www.bagnall.co.uk/oldsamples/gradient_text/' target='_blank' rel='noopener noreferrer'>gradient shaded appearance</a>,
											thereby removing the need for the extra graphic asset creation plus a more lightweight site for
											bandwidth. Better techniques are now available in more modern browsers.</p>

										<p>On my own initiative, I developed a canvas based effect <a
											href='https://www.bagnall.co.uk/oldsamples/wavybanner/'
											target='_blank' rel='noopener noreferrer'>'wavy banners'</a> to animate heraldic flags in page.</p>

									</Article>
									<Article
										id='eightRealms'
										title='8 Realms'
										expandNow={expandAll}
									>
										<p>I took this design and again, on my own initiaive, tried to make it more visually appealing and engaging.
											I added saccades animation to the eyes, periodic blinks and a sweeping hand movement. I also animated the hourglass logo.</p>
										<img src={eightRealms} alt={`8 Realms`} className={`eightRealmsScreenshot max400 centre`} />
									</Article>
									<p>HTML5, CSS3, javaScript, Photoshop, git, jQuery</p>
									{/*                        <p className='noprint'> See <%portfolio 'jagex'%></p>*/}
								</Article >

								{/* B R I G H T */}
								<Article
									id='Bright'
									logo={brightLogo}
									alt='Bright Logo'
									title='Bright Publishing Ltd'
									dates='Sep 2008'
									expandNow={expandAll}
								>
									<img className='site right' src={bright} alt='Bright Publishing Ltd' />
									<p><b>Web Developer</b> responsible for the website offerings of Bright publishing. Migrating the
										printed publications to the web and ensuring search engine optimisation, maximising revenue earning.
										Using HTML, XHTML, CSS, DHTML, AJAX, LAMP (Linux, Apache, MySQL and PHP) and JavaScript.
									</p>
								</Article >

								{/* H A R M O N Y */}
								<Article
									expandedByDefault={true}
									id='Harmony'
									href='https://www.harmony.co.uk'
									logo={harmonyLogo}
									mobileLogo={harmonyMobileLogo}
									alt='Harmony Logo'
									title='Harmony Internet'
									dates='Dec 2004 - Aug 2008'
									expandNow={expandAll}
								>
									<img src={harmony} alt='3 The Granary Buildings, Dunton'
										className='right site' />
									<p><b>Senior Web Developer</b> managing a team of three developers and one designer.</p>

									<p>Developing internet, intranet and extranet sites using HTML, CSS, ASP/VBScript, PHP, DHTML,
										AJAX (using JavaScript), Linux and Apache. DB includes MS SQL Server and MySQL. Using tools
										such as Adobe Dreamweaver and Photoshop.</p>

									<p>Web Based Mapping (Google Earth, Google Maps, MS Live Maps).</p>

									<p><a className='noprint reference' target='_blank' rel='noopener noreferrer' href='references/harmony.pdf'><span
										className='tick'></span>Reference from Jason Higgins, MD Harmony Internet Limited</a></p>
								</Article >

								{/* P R O Q U E S T */}
								<Article
									id='ProQuest'
									alt='ProQuest Logo'
									logo={proquestLogo}
									title='ProQuest'
									dates='Dec 2003 - Nov 2004'
									expandNow={expandAll}
								>
									<img src={proquest} alt='ProQuest' className='right site' />
									<p>My duties included design and management of MS Access based research databases, online
										research
										and MS Word and MS Access based mail merge runs. Assisted in evaluation of Siebel based
										publications management system and specification of IT equipment.</p>
								</Article >

								{/* A S P E N T E C H */}
								<Article id='Aspentech2002to2003'
									logo={aspentechLogo}
									alt='Aspentech Logo'
									href='https://www.aspentech.com'
									title='Aspentech Ltd'
									dates='Jan 2002 - Jan 2003'
									expandNow={expandAll}
								>
									<img src={aspentechOffice1} alt='Aspentech, Sheraton House, Cambridge, England' className='right site' />
									<p>A repeat contract to provide further functionality on the ZyQad product. Tools used included
										Visual Basic 6.0 and Intergraph RAD 2D.</p>
								</Article >

								{/* A S P E N T E C H */}
								<Article id='Aspentech1999to2001'
									logo={aspentechLogo}
									alt='Aspentech Logo'
									href='https://www.aspentech.com'
									title='Aspentech Ltd'
									dates='Aug 1999 - Jun 2001'
									expandNow={expandAll}
								>
									<img src={aspentechOffice2}
										alt='Aspentech, Sheraton House, Cambridge, England' className='right site' />
									<p>Working for <a href='https://www.aspentech.com' target='_blank' rel='noopener noreferrer'>Aspentech Ltd</a>, a Software
										house specialising in process control software. The brief was to provide utilities for
										communication of object data using XML messaging. Also to write software to allow import of
										legacy AutoCAD data to the new product. Tools used included Visual Basic 6.0 and Intergraph
										RAD 2D.</p>
								</Article >

								{/* V R M E */}
								<Article id='VRME' logo={vrme}
									alt='VRME'
									// style={{ width: '134px', height: '41px' }} /></a>
									href='http://www.bagnall.co.uk/vrme/'
									title='VRME'
									dates='Jul 1999 - August 1999'
									expandNow={expandAll}
								>
									<p>In-House Project to Develop <a href='http://www.bagnall.co.uk/vrme/' target='blank'>VRME</a>
									</p>
								</Article >

								{/* F U G R O */}
								<Article
									id='Fugro'
									href='http://www.fugro-inpark.nl'
									src={fugroLogo}
									alt='Fugro Inpark Logo'
									logo={fugroLogo}
									title='Fugro Inpark'
									dates='May 1999 - Jul 1999'
									expandNow={expandAll}
								>
									<img src={rietbaan} alt='Smallworld Netherlands, Capelle, Rotterdam'
										className='right site' />
									<p>Working on a prototype application using Smallworld GIS 3.0SP and Model.it 2.01, supervising
										and mentoring a small team of developers. The application was for Fugro's client Global
										Crossing.
										The project was based at Smallworld Netherland's offices near Rotterdam and Fugro offices
										in Amsterdam.</p>
								</Article >

								{/* S M A L L W O R L D   S Y S T E M S   L T D */}
								<Article
									id='Smallworld1999'
									logo={smallworldLogo}
									mobileLogo={smallworldMobileLogo}
									alt='Smallworld Systems Logo'
									href='https://www.ge.com/power'
									title='Smallworld Systems Ltd'
									dates='Apr 1999 - May 1999'
									expandNow={expandAll}
								>
									<img src={brunswickHouse1}
										alt='Smallworld, Brunswick House, Cambridge' className='right site' />
									<p>Bagnall Software Consultants Ltd has worked for Smallworld as part of the technical delivery
										team, developing application software for Phase 1 of the
										British Telecom Planning and Records
										Modernisation project.
									</p>
								</Article >

								{/* S M A L L W O R L D   S Y S T E M S   G M B H */}
								<Article
									id='Smallworld1998'
									logo={smallworldLogo}
									mobileLogo={smallworldMobileLogo}
									alt='Smallworld Systems Logo'
									href='https://www.ge.com/power'
									title='Smallworld GmbH'
									dates='Jan 1998 - Oct 1998'
									expandNow={expandAll}
								>
									<img src={europaring} alt='Smallworld GmbH Ratingen Offices'
										className='right site' />
									<p>Working as part of a team to meet the requirements of&nbsp;
										<a href='https://www.eon.com/en.html' target='_blank' rel='noopener noreferrer'>Bayernwerk Netkom</a> using Smallworld
										GIS
										and Model.it. Based in Ratingen and Darmstadt.</p>

									<p><a className='noprint reference' target='_blank' rel='noopener noreferrer' href='/references/os_ref.html'><span
										className='tick'></span>Reference from Oliver Schlageter, Professional Services, Smallworld
										System GmbH</a></p>
								</Article >

								{/* G A R D L I N E   I N F O T E C H */}
								<Article
									id='Gardline'
									logo={gardlineLogo}
									mobileLogo={gardlineMobileLogo}
									href='http://www.gardline.com'
									alt='Garline Infotech'
									title='Gardline Infotech'
									dates='Sep 1997'
									expandNow={expandAll}
								>
									<p>Developed two tools to increase data capture efficiency for this data capture department
										using Smallworld GIS.</p>
								</Article >

								{/* S C O T T I S H   H Y D R O - E L E C T R I C   P L C */}
								<Article
									id='ScottishPower'
									logo={shLogo} src='../images/scottish_hydro.gif'
									alt='Scottish Hydro-Electric Logo'
									title='Scottish Hydro-Electric plc'
									dates='Oct 1996 - May 1997'
									expandNow={expandAll}
								>

									<img src={shOffice} alt='Scottish Hydro Electric, Dundee Offices'
										className='right site' />

									<p>Responsible for Smallworld GIS data capture department support and systems administration
										at Scottish Hydro's offices in Dundee. Design of new backup strategy and development of
										shell
										scripts.</p>

									<p><a className='noprint reference' target='_blank' rel='noopener noreferrer' href='/references/bl_ref.pdf'><span
										className='tick'></span>Reference from Bob Littlejohn, Mapping Services Manager, Scottish
										Hydro-Electric plc</a></p>
								</Article >

								{/* S E M A   G R O U P   S Y S T E M S   L T D */}
								<Article
									id='SEMA'
									logo={semaLogo}
									mobileLogo={semaMobileLogo}
									alt='SEMA Logo'
									title='SEMA Group Systems Ltd'
									dates='Apr 1996 - Oct 1996'
									expandNow={expandAll}
								>
									<img src={semaOffice} alt='Sema Group, Discovery House, Cambridge'
										className='right site' />

									<p>My Assignment to SEMA Group Systems Ltd
										involved installation of, and systems administration for a DEC Alpha Station 200,
										installation,
										configuration and administration of Smallworld GIS.</p>
									<p>I wrote utilities for Data Transfer,
										Data Cleaning and Data Warehousing. I also produced a utility to allow automatic geolocation
										of
										new data objects. The work involved creation of triggers, file handling utilities, parsing
										of
										data files and the writing of a new, recursive network tracing utility.</p>
								</Article >

								{/* S M A L L W O R L D   S Y S T E M S   L T D */}
								<Article
									id='Smallworld1995to1996'
									logo={smallworldLogo}
									mobileLogo={smallworldMobileLogo}
									alt='Smallworld Systems Logo'
									href='https://www.ge.com/power'
									title='Smallworld Systems Ltd'
									dates='Apr 1999 - May 1999'
									expandNow={expandAll}
								>
									<img src={brunswickHouse2} alt='Smallworld, Brunswick House, Cambridge'
										className='right site' />
									<p>For Smallworld Systems Ltd in Cambridge, I attended 8 weeks of training to become familiar
										with
										their systems and with their own programming language 'Magik', which is an object oriented
										language
										similar to Smalltalk. I was subsequently retained by Smallworld on two separate contracts,
										to work
										on projects for their clients. </p>

									<img src={smallworldWCP} alt='Smallworld World Class Partner' className='left' />

									<p>Bagnall Software Consultants Ltd was Authorised as a World Class Partner following my
										subsequent experience and accreditation as application developer in April 1997. </p>

									<p>In the summer of 1997 I assisted with the Smallworld Summer School at the&nbsp;
										<a href='http://www.ncl.ac.uk' target='_blank' rel='noopener noreferrer'>University of Newcastle on Tyne</a>. This
										voluntary
										work was to provide assistance during the practical sessions in the laboratory.</p>
									<p>In 1999
										Bagnall Software Consultants Ltd attained advanced accreditation status. </p>

									<p><a className='noprint reference' target='_blank' rel='noopener noreferrer' href='/references/dh_ref.pdf'><span
										className='tick'></span>Reference from David Hathaway, Sector Manager, Professional Services
										Group.</a></p>
								</Article >

								{/* C I M A G E */}
								<Article
									id='Cimage'
									logo={cimageLogo}
									alt='Cimage Logo'
									title='Cimage Corporation and Cimage International'
									dates='Nov 1991 - Mar 1995'
									expandNow={expandAll}
								>
									<img src={cimageOffice} alt='Cimage Corporation, Ann Arbor, Michigan'
										className='right site' />

									<p>I established Bagnall Software Consultants while working in America. My first appointment was
										to an American Corporation whose development offices are in Ann Arbor near Detroit.</p>

									<p>The project involved porting over 300,000 lines of 'C' source code to VMS on a DECStation
										3100,
										making use of DECWindows (XWindows), Netwise RPC, Wollongong WIN/TCP, Wollongong Pathway
										Client
										NFS for VMS and Oracle for VMS. </p>

									<p>Since this client was a CSO for Digital, I was able to investigate the feasibility of porting
										their products to DEC Alpha machines running VMS, OSF1 and Windows NT<sup>&trade;</sup>, in advance
										of
										their launch in the USA. and the UK. </p>

									<p>I evaluated GQL and PowerBuilder. I also attended a PowerBuilder training course. As well as
										'C', I wrote applications using PowerBuilder and Microsoft Visual Basic<sup>&trade;</sup>, including
										a
										Powerbuilder Product Change Request system for a large UK automotive manufacturer and a
										Visual
										Basic<sup>&trade;</sup> Bill of Materials prototype for an aerospace company. </p>

									<p>While working at the UK. offices of the corporation, I wrote software to interface their
										product
										to the software of their clients. I have been required to represent the client at a number
										of
										their installations, and I have developed a number of new applications for the company.
										Latter
										projects included ADS development for AutoCAD undertaken in Michigan.</p>

									<p><a className='noprint reference' style={{ marginBottom: '1.5em' }} target='_blank' rel='noopener noreferrer' href='/references/rh_ref.pdf'><span className='tick'></span>Reference from R S Hill, Managing Director.</a>
										<a className='noprint reference clearleft' style={{ marginTop: '1.5em' }} target='_blank' rel='noopener noreferrer' href='/references/gm_ref.pdf'><span className='tick'></span>Reference from G Mountstevens, Technical Support Manager.</a></p>
								</Article >

								{/* R O B A R Y   S O F T W A R E   L T D */}
								<Article
									id='RoBary'
									logo={robaryLogo}
									alt='RoBary Logo'
									title='RoBary Software Ltd'
									dates='Nov 1989 - Nov 1991'
									expandNow={expandAll}
								>

									<p>I was employed by Robary Software Ltd. as a Software Consultant. This gave me my first
										introduction to the nature of contract work, requiring rapid familiarisation with products,
										companies and working methods. Projects included: </p>

									<Article
										id='RoBaryAspentech'
										title='VMS FORTRAN Process Simulation Application'
										expandNow={expandAll}
									>
										<p>FORTRAN on DEC VAX, Working in a team comprising both permanent employees and contract
											staff, to develop a large system for chemical process industry simulation. I was Involved in
											design, implementation, testing and refinement of the product.</p>
									</Article >

									<Article
										id='RoBaryEarthSpan'
										title='dBase IV Stock Control and Operational Management System'
										expandNow={expandAll}
									>
										<p>This project for a small industrial manufacturer of building materials was a dBase IV
											customisation for stock control, resource scheduling and operational management.</p>
									</Article>

									<Article
										id='RoBaryMarshalls'
										title='Word Processor Documentation Converter'
										expandNow={expandAll}
									>
										<p>Produced for a large Engineering and Aerospace company, this software converted CPT
											Turnkey Word
											Processor format documents to Microsoft Word<sup>&trade;</sup>, retaining as many of the
											formatting and
											style attributes of the original documents as possible. The product was written in 'C'
											and based
											on IBM PC.</p>
									</Article>

									<Article
										id='RoBaryNHS'
										title='Traffic Flow Analysis'
										expandNow={expandAll}
									>
										<p>For a large government organisation, this was a Borland Quattro Pro macro and Paradox PAL
											customisation for traffic flow modelling.</p>

										<p>This project employed unorthodox uses of spread-sheet technology. It required a
											sophisticated
											spreadsheet system and a Beta test agreement with Borland to be able to utilise what
											were at the
											time, advanced features.</p>
									</Article>

									<Article
										id='RoBaryTrainingCourses'
										title='Training Courses'
										expandNow={expandAll}
									>

										<p>I prepared and presented training courses for Lotus<sup>&reg;</sup> 123<sup>&reg;</sup>, Windows
											and DOS.</p>
									</Article>
								</Article >

								{/* C I S */}
								<Article
									id='CIS'
									logo={cisLogo}
									alt='CIS Logo'
									title='Cambridge Interactive Systems'
									dates='May 1985 - Nov 1989'
									expandNow={expandAll}
								>

									<img src={harstonMill} alt='Harston Mill, Cambridge' className='right site' />

									<p>I started work at Cambridge Interactive Systems Ltd. (Latterly Computervision, then Prime) on
										May 7th 1985 as a Software Engineer. Projects included:</p>

									<Article
										id='CISSourceTransfer'
										title='Source Transfer Utility'
										expandNow={expandAll}
									>
										<p>Using the tape drives of the VAX, PRIME and SUN machines, this was written in 'C', DCL,
											CPL and
											C shell. It provided a simple user interface which was identical across all three machines.</p>
									</Article >

									<Article
										id='CISTektronixDrivers'
										title='Tektronix 4107 &amp; 4109 Drivers'
										expandNow={expandAll}
									>
										<p>I wrote drivers for the Tektronix 4107 &amp; 4109 display devices</p>
									</Article>

									<Article
										id='CISFileConversion'
										title='Source File Conversion Utility'
										expandNow={expandAll}
									>
										<p>I joined the NC team in December 1985 and began by producing a source-file conversion
											utility, to allow portability of source code from PRIME to VAX.</p>
									</Article>

									<Article
										id='CISNCPostProcessors'
										title='NC Post-Processors'
										expandNow={expandAll}
									>
										<p>I wrote Post-Processors for CNC machine tools and investigated the suitability of Generic
											Post Processor Products for use with Medusa NC.</p>

										<p>Before leaving CIS I was responsible for all CNC Post-Processor interfaces to MEDUSA NC,
											and supervised a number of contract personnel who wrote custom Post-Processors.</p>
									</Article>
								</Article>

								{/* M E T I E R */}
								<Article id='Metier'
									logo={metierLogo}
									alt='Metier Logo'
									title='Metier Management Systems Ltd'
									dates='Jun 1983 - May 1985'
									expandNow={expandAll}
								>
									<img src={metierOffice} alt='Metier Management Systems, Fore Street, Ipswich' className='right site' />

									<p>I started work at Metier Management Systems Ltd. in Ipswich, at the end of June 1983 as a
										Graphics Programmer. Metier supplied hardware and produced software for Project Management
										Applications.</p>

									<p>One of their products was a system for the automatic generation of a network plot based on
										data which are stored in a relational database. </p>

									<p>Metier diversified from their Hewlett Packard and DEC hardware to provide software for the
										IBM range of machines from mainframe to micro.</p>
									<Article
										id='MetierLinkedBarcharts'
										title='Linked Bar charts'
										expandNow={expandAll}
									>
										<p>The work I did at Metier involved mainly diagrammatic Computer Graphics, for example, a
											system for the automatic generation of resource-scheduling, Linked-Bar charts.</p>
									</Article >

									<Article
										id='MetierSymbolEditor'
										title='Symbol Editor'
										expandNow={expandAll}
									>
										<img src={blackbird} alt='Blackbird SAMSO symbol' className='left'
											id='samso' />
										<p>My last project at Metier was to produce a simple drafting package for Hewlett Packard
											2647 and 2627 terminals.</p>
										<p>The system allowed the user to draft symbols which could then be held in a database and
											could consequently be used to annotate automatically generated plots as 'SAMSO' symbols.</p>
									</Article>
								</Article >

								{/* E D U C A T I O N */}
								<Article
									id='Education'
									title='Education'
									expandNow={expandAll}
								>
									<Article
										id='EducationUEA'
										logo={ueaLogo}
										href='http://www.uea.ac.uk'
										alt='UEA Logo'
										title='The University of East Anglia'
										dates='1980 - 1983'
										expandNow={expandAll}
									>

										<img src={uea} alt='University of East Anglia' className='right site' />
										<p>Degree: B.Sc. (Hons) Computing Studies</p>
									</Article >
									<Article
										id='EducationJoRo'
										logo={joroLogo}
										href='http://www.josephrowntreeschool.co.uk'
										alt='Joseph Rowntree Logo'
										title='The Joseph Rowntree School'
										dates='1973 - 1980'
										expandNow={expandAll}
									>

										<table id='SchoolResults'>
											<tbody>
												<tr>
													<th>Subject</th>
													<th>Level</th>
													<th>Grade</th>
													<th>Date</th>
												</tr>
												<tr>
													<td>Mathematics</td>
													<td>O</td>
													<td>A</td>
													<td>6:1978</td>
												</tr>
												<tr>
													<td>Geography</td>
													<td>O</td>
													<td>A</td>
													<td>6:1978</td>
												</tr>
												<tr>
													<td>Physics</td>
													<td>O</td>
													<td>B</td>
													<td>6:1978</td>
												</tr>
												<tr>
													<td>English Language</td>
													<td>O</td>
													<td>B</td>
													<td>6:1978</td>
												</tr>
												<tr>
													<td>French</td>
													<td>O</td>
													<td>B</td>
													<td>6:1978</td>
												</tr>
												<tr>
													<td>Chemistry</td>
													<td>O</td>
													<td>C</td>
													<td>6:1978</td>
												</tr>
												<tr>
													<td>German</td>
													<td>O</td>
													<td>C</td>
													<td>6:1978</td>
												</tr>
												<tr>
													<td>English Literature</td>
													<td>O</td>
													<td>C</td>
													<td>6:1978</td>
												</tr>
												<tr>
													<td>Art (extracurricular study)
														<a href='references/joan_reavill_reference.pdf'
															target='_blank'
															rel='noopener noreferrer'
															className='noprint smallReference'>
															<span
																title='Reference from Joan Reavill'
																className='tick' />
														</a>
													</td>
													<td>O</td>
													<td>C</td>
													<td>4:1979</td>
												</tr>
												<tr>
													<td>English Studies</td>
													<td>CSE</td>
													<td>2</td>
													<td>6:1978</td>
												</tr>
												<tr>
													<td>Additional Mathematics</td>
													<td>A/O</td>
													<td>B</td>
													<td>6:1979</td>
												</tr>
												<tr>
													<td>Physics</td>
													<td>A</td>
													<td>C</td>
													<td>6:1980</td>
												</tr>
												<tr>
													<td>Mathematics (Pure &amp; Applied)</td>
													<td>A</td>
													<td>E</td>
													<td>6:1980</td>
												</tr>
												<tr>
													<td>General Paper</td>
													<td>A</td>
													<td>E</td>
													<td>6:1980</td>
												</tr>
												<tr>
													<td>Further Mathematics</td>
													<td>A</td>
													<td>O</td>
													<td>6:1980</td>
												</tr>
												<tr>
													<td>Chemistry</td>
													<td>A</td>
													<td>O</td>
													<td>6:1980</td>
												</tr>
											</tbody>
										</table>
									</Article>
								</Article >
							</div > {/* #content */}
							<footer>
								<a
									href='http://www.bagnall.co.uk'
									target='blank'
									className='copyright no-underline'

								>&copy; {yearNow}&nbsp;<img
										alt='BSC Logo'
										className='logo'
										src={bscSvg}
									/>
									Bagnall Software Consultants Ltd</a>
							</footer>

						</div >
					</div >
				</React.StrictMode >
			</div >
		);
	};
}

export default App;